import { useState, useEffect, useRef } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import { BSToAD } from "bikram-sambat-js";
import { CalendarOutlined } from "@ant-design/icons";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import "./addUpdate.css";

const BSDatePicker = (props) => {
  const handleDateChange = (val) => {
    if (val) {
      const englishDate = BSToAD(val);
      props.setDate({
        nepali: val,
        english: englishDate,
      });
    } else {
      props.setDate({
        nepali: "",
        english: "",
      });
    }
  };

  const resetAction = () => {
    props.setDate({ nepali: "", english: "" });
  };

  return (
    <div id={props.id} className="bs-date-picker">
      <div className="wrapperForInputDiv">
        <div className="wrapperLabel">
          <label className="dateLabel">{props.label}</label>
        </div>
        <div className="nepali-datepicker-container">
          <Form.Item className="nepali-datepicker">
            <NepaliDatePicker
              name={props.name}
              className="nepali-datepicker"
              options={{ calenderLocale: "en", valueLocale: "en" }}
              placeholder="YYYY-MM-DD"
              defaultDate={props.date?.nepali}
              value={props.date?.nepali}
              onChange={handleDateChange}
            />
          </Form.Item>
          <CalendarOutlined
            style={{ cursor: "pointer" }}
            className="calendar"
          />
          <input
            type="text"
            value={props.date.english ? props.date.english : ""}
            className="englishInputClass"
          />
          <button
            className="reset-date-button"
            onClick={resetAction}
            type="button"
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
};

BSDatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.any,
  setDate: PropTypes.func,
};

export default BSDatePicker;
